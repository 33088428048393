import React from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { DocumentPreviewProps } from '../DocumentProcessingModel';
import { useAuth } from '../../auth';

export const DocumentPreview: React.FC<DocumentPreviewProps> = ({
    fileName,
    doctype,
}) => {

    const { currentUser } = useAuth();

    return (
        <div className={`col-sm-12 col-md-6`}>
            <div
                className={`card card-xl-stretch`}
                style={{ height: '70vh', overflowY: 'scroll' }}
            >
                <div className="card-header border-0">
                    <h3 className="card-title fw-bold text-dark">
                        <KTIcon
                            iconName="screen"
                            className="fs-2x text-primary"
                        />
                        &nbsp; Preview Document
                    </h3>
                </div>
                {/* Render file preview */}
                {fileName !== '' && doctype !== '' ? (
                    <iframe
                        src={`/media/${currentUser?.username}/${doctype}/${fileName}`}
                        title="PDF Preview"
                        width="100%"
                        height="500px"
                    ></iframe>
                ) : (
                    <div className="card-body">
                        <h4 className="text-center">Select a document to preview it here</h4>
                    </div>
                )}
                {/* ... */}
            </div>
        </div>
    );
};
