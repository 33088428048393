import React, { useEffect, useState } from "react";
import { Blocks } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { KTIcon } from "../../../_metronic/helpers";
import { documentApproval, documentAssignTo, documentFeedback, filesByUser, getAllMember, processDocuments } from "../../api";
import { useAuth } from "../../modules/auth";
import Feedback from "../../modules/documentValidations/Feedback";
import { UploadDocuments } from "../../modules/documentprocessing/documentlibrary/UploadDocuments";
import { ButtonRow } from "../../modules/documentprocessing/ButtonRow";
import { DocumentLibraryView } from "../../modules/documentprocessing/documentlibrary/DocumentLibraryView";
import DocumentReviewView from "../../modules/documentprocessing/documentreview/DocumentReviewView";
import { HeaderSection } from "../../modules/documentprocessing/HeaderSection";
import AssignDocument from "../../modules/teams/AssignDocument";
// import { saveAs } from 'file-saver';

type Props = {};

type fileType = Array<string>;

interface TeamMemberObject {
  username: string;
  email: string;
  password: string;
  organizationId: number;
  type: string;
}

type MyObject = {
  [key: string]: string[];
};

interface Folder {
  files: Array<{
    blobLocation: string,
    containerName: string,
    fileOriginalName: string,
    fileUrl: string,
    processed: number,
    tag: string,
    userEmail: string,
    createdAt: string,
    assign_to: string,
    feedback: string,
    approval: string
  }>
}

type FolderObject = {

}

export default function DocumentProcessingNew({ }: Props) {
  const { currentUser } = useAuth();
  const { doctype } = useParams()

  const [folder, setFolder] = useState<Folder | null>();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [result, setResult] = useState<MyObject>({});
  const [resultNew, setResultNew] = useState<MyObject>({});
  const [resObj, setResObj] = useState<MyObject>({});
  const [viewtables, setViewTables] = useState<Array<MyObject>>([]);
  const [tables, setTables] = useState<Array<Array<MyObject>>>([]);
  const [tableHeader, setTableHeader] = useState<string[][]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [downlodingJson, setDownloadingJson] = useState<boolean>(false);
  const [responseJson, setResponseJson] = useState<Array<Array<Object>>>([])
  const [resultView, setResultView] = useState<string>("default")
  const [sasUrl, setSasUrl] = React.useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState('csv')
  const [respError, setRespError] = useState(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string>()
  const [downloadType, setDownloadType] = useState<string>()
  const [teams, setTeams] = useState<string[]>()
  const [feedback, setFeedback] = useState<string>()
  const [teamMember, setTeamMember] = useState<string>()
  const [processAction, setProcessAction] = useState<string | null>(null)

  const handleDownload = async (format: string, fileUrl?: string) => {
    try {
      await handleProcess(fileUrl);
      setDownloadFormat(format)
      console.log(responseJson[0]);
      setDownloadType(format);
      // if (format === 'csv' || format === 'xlsx') {
      //   // Set a flag indicating the type of download needed
      //   setDownloadType(format);
      // } else if (format === 'json' || format === 'txt') {
      //   setDownloadType(format);
      // } else {
      //   alert('Please select a valid file type');
      // }
    } catch (error) {
      console.error('Error occurred:', error);
      // Handle errors here
    }
  };

  const handleCSVDownload = () => {
    var tempJsonFile = responseJson[0] ? responseJson[0] : []
    var tempResultJsonData: MyObject = {}
    console.log("length of resultnew: ", Object.keys(resultNew).length)
    Object.keys(resultNew).length > 0 && Object.entries(resultNew).map(([key, value]) => {
      if (typeof value != "object") {
        tempResultJsonData[key] = value
      }
    })
    console.log(tempResultJsonData)
    tempJsonFile.unshift(tempResultJsonData)
    console.log(tempJsonFile)
    // create file in browser
    const jsonFileName = fileName.slice(0, fileName.indexOf('.pdf'));
    const json = JSON.stringify(tempJsonFile, null, 2);
    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(tempJsonFile);
    utils.book_append_sheet(workbook, worksheet, "");
    // downloadFormat == 'csv' ? writeFile(workbook, `${jsonFileName}.csv`) : writeFile(workbook, `${jsonFileName}.xlsx`);
    downloadFormat == 'csv' ? writeFile(workbook, `data.csv`) : writeFile(workbook, `data.xlsx`);

  };

  const handleJsonDownload = () => {
    setDownloadingJson(true)
    var data, filename, link;
    var tempJsonFile = responseJson[0] ? responseJson[0] : []
    var tempResultJsonData: MyObject = {}
    console.log("length of resultnew: ", Object.keys(resultNew).length)
    Object.keys(resultNew).length > 0 && Object.entries(resultNew).map(([key, value]) => {
      if (typeof value != "object") {
        tempResultJsonData[key] = value
      }
    })
    console.log(tempResultJsonData)
    tempJsonFile.push(tempResultJsonData)
    console.log(tempJsonFile)
    // create file in browser
    const jsonFileName = fileName.slice(0, fileName.indexOf('.pdf'));
    const json = JSON.stringify(tempJsonFile, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    link = document.createElement("a");
    link.href = href;
    downloadFormat == 'json' ? link.download = jsonFileName + ".json" : link.download = jsonFileName + ".txt";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    setDownloadingJson(false)
  }

  const handleProcess = (fileLink?: string): Promise<void> => {
    // Set initial states
    setProcessing(true); // Set processing state to true
    setRespError(false); // Reset response error state
    setViewTables([]); // Clear view tables
    setResponseJson([]); // Clear response JSON
    setSelectedFiles([]); // Clear selected files
    setResultNew({}); // Clear result new

    let fileList: Array<Object> = []; // Initialize an array to hold file objects

    // If a fileLink is provided, add it to the selectedFiles array
    if (fileLink) {
      let tempSelectedFiles = selectedFiles;
      tempSelectedFiles.push(fileLink);
      setSelectedFiles(tempSelectedFiles);

      // Create file object and push it to fileList
      let fileObj = {
        fileLink: fileLink
      };
      fileList.push(fileObj);
    } else {
      // If no fileLink is provided, iterate through selectedFiles
      selectedFiles.forEach((selectedFile) => {
        // Create file object for each selected file and push it to fileList
        let fileObj = {
          fileLink: selectedFile
        };
        fileList.push(fileObj);
      });
    }

    // Make API call to process documents
    return new Promise<void>((resolve, reject) => {
      processDocuments({
        files: fileList,
        username: currentUser?.username,
        userEmail: currentUser?.email,
        tag: doctype
      }).then((res) => {
        // Process response data
        let tempTableHeader: string[][] = []; // Temporary array to hold table headers
        let tempJsonResp: Object[][] = []; // Temporary array to hold JSON response
        let responseNew = res.data; // Get response data
        setResultNew(responseNew); // Set result new

        // Iterate through response data
        Object.entries(responseNew).map(([key, value], index) => {
          console.log("type: ", typeof (value));
          let resJson: Array<Object> = [];

          // Check if value is an array and not empty
          if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object") {
            let viewKeyList = Object.keys(value[0] || {}); // Get keys of first object in the array
            console.log(viewKeyList);
            tempTableHeader.push(viewKeyList); // Push keys to temporary table header array
            setTableHeader([...tempTableHeader]); // Set table header

            // Iterate through array elements
            value.map((val) => {
              var abcObj: { [key: string]: string } = {}; // Create object to hold key-value pairs
              Object.entries(val).map(([itemKey, itemValue], index) => {
                abcObj[itemKey] = String(itemValue); // Convert each value to string and assign to object
              });
              resJson.push(abcObj); // Push object to response JSON array
            });
          }
          // Check if response JSON array is not empty and push it to temporary response array
          resJson.length > 0 && tempJsonResp.push(resJson);
        });

        sessionStorage.setItem("response", JSON.stringify(res.data)); // Store response data in session storage
        setResponseJson(tempJsonResp); // Set response JSON
        console.log(tempJsonResp);

        // Make API call to get files by user
        filesByUser({ email: currentUser?.email, tag: doctype, usertype: currentUser?.usertype }).then((res) => {
          setFolder(res.data); // Set folder state with response data
        });

        setProcessing(false); // Set processing state to false
        resolve(); // Resolve the promise
      }).catch((error) => {
        setRespError(true); // Set response error state to true
        setProcessing(false); // Set processing state to false
        reject(error); // Reject the promise with error
      });
    });
  };

  const handleDocumentApproval = (approval: string) => {
    console.log(selectedFiles)
    if (selectedFiles.length > 0) {
      setProcessAction(approval)
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      documentApproval({
        useremail: currentUser?.email,
        files: files,
        approval: approval
      }).then((res) => {
        setProcessAction(null)
        setSelectedFiles([]);
        alert(res.message)
        setRefresh(true)
      })
    } else {
      alert("No file selected")
    }
  }

  const handleAssingMember = (e: any) => {
    // e.preventDefault()
    if (selectedFiles.length > 0) {
      setProcessAction("assinging")
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      documentAssignTo({
        useremail: currentUser?.email,
        files: files,
        assignTo: teamMember
      }).then((res) => {
        setProcessAction(null)
        setSelectedFiles([]);
        alert(res.message)
        setRefresh(true)
      })
    } else {
      alert("No file selected")
    }
  }

  const handleSubmitFeedback = (e: any) => {
    // e.preventDefault()
    if (selectedFiles.length > 0) {
      setProcessAction("feedback")
      let files: Array<Object> = [];
      selectedFiles.forEach((selectedFile) => {
        let fileObj = {
          file_url: selectedFile
        };
        files.push(fileObj);
      });
      documentFeedback({
        useremail: currentUser?.email,
        files: files,
        feedback: feedback
      }).then((res) => {
        setProcessAction(null)
        setSelectedFiles([]);
        alert(res.message)
        setRefresh(true)
      })
    } else {
      alert("No file selected")
    }
  }

  useEffect(() => {
    getAllMember({ organizationId: currentUser?.organizationId }).then((res) => {
      //  Filtering out emails of objects with type !== 'superadmin'
      const emails = res.data
        .filter((user: TeamMemberObject) => user.type !== 'superadmin')
        .map((user: TeamMemberObject) => user.email);

      setTeams(emails)
    });
  }, [])

  const toggleState = () => {
    setPreview(prevState => !prevState); // Invert the current state value
  };

  useEffect(() => {
    setFolder(null)
    filesByUser({ email: currentUser?.email, tag: doctype, usertype: currentUser?.usertype }).then((res) => {
      setFolder(res.data);
    });
  }, [doctype]);

  useEffect(() => {
    if (refresh) {
      let tempFolder = { files: [] }
      setFolder(tempFolder)
      filesByUser({ email: currentUser?.email, tag: doctype, usertype: currentUser?.usertype }).then((res) => {
        setFolder(res.data);
        setRefresh(false)
      });
    }
  }, [refresh])

  useEffect(() => {
    if (downloadType === 'csv' || downloadType === 'xlsx') {
      handleCSVDownload(); // Call CSV download when downloadType state changes
    }
    if (downloadType === 'txt' || downloadType === 'json') {
      handleJsonDownload(); // Call CSV download when downloadType state changes
    }
  }, [downloadType]); // Run this effect when downloadType changes

  return (
    <div>

      {/* Upload Document */}
      <div className="modal fade" id="kt_modal_assosiate_student" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Upload Document</h2>
              <button
                className="btn btn-sm btn-icon btn-active-color-primary"
                // data-bs-dismiss="modal"
                onClick={(e) => setCloseModal(true)}
              >
                <KTIcon iconName="cross" className="fs-1" />
              </button>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {
                currentUser?.usertype == 'superadmin' ?
                  <UploadDocuments closeModal={closeModal} setRefresh={setRefresh} setCloseModal={setCloseModal} /> :
                  <p className="fs-1 fw-bold text-center">Feature Disabled</p>
              }
            </div>
          </div>
        </div>
      </div>

      {/* Preview Document */}
      <div className="modal fade" id="kt_modal_preview" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Preview Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <iframe
                  src={sasUrl}
                  title="PDF Preview"
                  width="100%"
                  height="500px"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Assign Team */}
      <div
        className="modal fade"
        id="kt_modal_assign"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Assign Document to Team Member</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <AssignDocument
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setProcessAction={setProcessAction}
              setRefresh={setRefresh}
            />
          </div>
        </div>
      </div>

      {/* Provide Feedback */}
      <div
        className="modal fade"
        id="kt_modal_feedback"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Provide Give Feedback about the Processed Document</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <Feedback selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} setRefresh={setRefresh} />
          </div>
        </div>
      </div>

      <div className="mx-2 mb-xl-8" style={{ borderRadius: '0px', minHeight: '90vh' }}>
        {/* begin::Header */}
        <HeaderSection doctype={doctype ? doctype : ""} />
        {/* end::Header */}

        <ButtonRow
          tables={tables}
          handleProcess={handleProcess}
          resultNew={resultNew}
          responseJson={responseJson}
          setResultView={setResultView}
          resultView={resultView}
          toggleState={toggleState}
          preview={preview}
        />

        {folder && folder.files.length > 0 ? (
          <div className="card-body pt-5">
            {
              preview ?
                <DocumentReviewView
                  fileName={fileName}
                  doctype={doctype ? doctype : ""}
                  resultNew={resultNew}
                  resultView={resultView}
                  tableHeader={tableHeader}
                  responseJson={responseJson}
                  processAction={processAction}
                  selectedFiles={selectedFiles}
                  processing={processing}
                  handleDocumentApproval={handleDocumentApproval}
                /> :
                <>
                  {
                    folder &&
                    <DocumentLibraryView
                      doctype={doctype ? doctype : "Document"}
                      documents={folder.files}
                      setRefresh={setRefresh}
                      preview={preview}
                      toggleState={toggleState}
                      setFileName={setFileName}
                      onReview={handleProcess}
                      handleDownload={handleDownload}
                    />
                  }
                </>
            }
          </div>
        ) : (
          <div className="row">
            {folder?.files.length == 0 ?
              <div className="card-body pt-5">
                <p className="fw-bold fs-2 text-center">No Files Present. Please Upload New Files</p>
              </div>
              : <Blocks
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
              />}
          </div>
        )}
      </div>
    </div>
  );
}
