import React, { useState } from 'react';
import { utils, writeFile } from "xlsx";
import { KTIcon } from '../../../_metronic/helpers';

interface ButtonRowProps {
    handleProcess: () => void;
    resultNew: Record<string, any>;
    responseJson: any; // Define type for responseJson as needed
    setResultView: React.Dispatch<React.SetStateAction<string>>;
    resultView: string | undefined; // Update resultView type
    tables: Array<Array<MyObject>>
    toggleState: () => void;
    preview: boolean;
    // Add more props as needed
}

interface MyObject {
    [key: string]: string[];
};


export const ButtonRow: React.FC<ButtonRowProps> = ({
    tables,
    preview,
    handleProcess,
    resultNew,
    responseJson,
    setResultView,
    resultView,
    toggleState
}) => {
    const [closeModal, setCloseModal] = useState<boolean>(false);
    const [downloadFormat, setDownloadFormat] = useState('csv')
    const [exportFormat, setExportFormat] = useState('')
    const [downlodingJson, setDownloadingJson] = useState<boolean>(false);
    const [selectedCsv, setSelectedCsv] = useState<{ [key: string]: any }>([]);
    const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
    const [fileName, setFileName] = useState<string>("");

    const handleToggle = () => {
        toggleState(); // Toggle the state in the parent component
    };

    const handleDownload = () => {
        if (downloadFormat == 'csv') {
            handleCSVDownload()
        } else if (downloadFormat == 'json') {
            handleJsonDownload()
        } else if (downloadFormat == 'xlsx') {
            handleCSVDownload()
        } else if (downloadFormat == 'txt') {
            handleJsonDownload()
        } else {
            alert("Please select valid file type")
        }

    }

    const handleExport = () => {
        if (exportFormat == 'snowflake') {
            // // api call
            // setTimeout(() => {
            //     alert("Data successfully inserted into snowflake")
            // }, 2000);
        } else if (exportFormat == 'mysql') {
            alert("This feature not Supported in Trial Plan")
        } else if (exportFormat == 'crm') {
            alert("This feature not Supported in Trial Plan")
        } else if (exportFormat == 'erp') {
            alert("This feature not Supported in Trial Plan")
        } else {
            alert("Please select valid Database")
        }

    }

    const handleCSVDownload = () => {
        // Iterate over the values and create a 2D array
        console.log("tables length csv: ", tables.length);
        tables.map((docTable, i) => {
            console.log("csv index : ", i);
            console.log("csv docs : ", docTable);
            const workbook = utils.book_new();
            const worksheet = utils.json_to_sheet([selectedCsv[i]]);
            console.log(tables);
            // Iterate over the values and create a 2D array
            docTable.map((table, index) => {
                const array2D = Object.entries(table).map(([key, values]) => [
                    ...values
                ]);
                const transposeArray = (array: any[][]): any[][] =>
                    array[0].map((_, colIndex) => array.map((row) => row[colIndex]));
                const transposedArray = transposeArray(array2D);
                console.log("tanspose arr : ", transposedArray);

                const transaction = utils.aoa_to_sheet([...transposedArray]);
                utils.book_append_sheet(workbook, transaction, `sheet${index + 1}`);
            });
            utils.book_append_sheet(workbook, worksheet, "");
            downloadFormat == 'csv' ? writeFile(workbook, `${selectedFileNames[i]} Report.csv`) : writeFile(workbook, `${selectedFileNames[i]} Report.xlsx`);
        });
    };

    const handleJsonDownload = () => {
        setDownloadingJson(true)
        var data, filename, link;
        var tempJsonFile = responseJson[0]
        var tempResultJsonData: MyObject = {}
        Object.entries(resultNew).map(([key, value]) => {
            if (typeof value != "object") {
                tempResultJsonData[key] = value
            }
        })
        console.log(tempResultJsonData)
        tempJsonFile.push(tempResultJsonData)
        console.log(tempJsonFile)
        // create file in browser
        const jsonFileName = fileName.slice(0, fileName.indexOf('.pdf'));
        const json = JSON.stringify(tempJsonFile, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        link = document.createElement("a");
        link.href = href;
        downloadFormat == 'json' ? link.download = jsonFileName + ".json" : link.download = jsonFileName + ".txt";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        setDownloadingJson(false)
    }

    const handleBulkProcess = () => {
        alert("Bulk Processing is not Supported in Trial Plan")
    }

    return (
        <div className="row">
            <div
                className="d-flex justify-content-between my-1"
                data-kt-user-table-toolbar="base"
            >
                <div className="px-5">
                    {
                        !preview && <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-180px "
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_assosiate_student"
                            onClick={(e) => setCloseModal(false)}
                        >
                            <KTIcon iconName="plus" className="fs-3" />
                            Upload Audio
                        </button>}
                    {
                        preview && <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-180px "
                            // data-bs-toggle="modal"
                            // data-bs-target="#kt_modal_assosiate_student"
                            onClick={handleToggle}
                        >
                            Back to Audio Library View
                        </button>
                    }
                </div>
                <div>
                    {/* <button
                        type="button"
                        className="btn btn-primary me-3 my-3 w-200px"
                        onClick={handleBulkProcess}
                    >
                        <p className="fs-1 fw-bolder pb-0 mb-0">
                            <KTIcon iconName="gear" className="fs-1" /> Process
                        </p>
                    </button> */}
                    {(preview && Object.keys(resultNew).length !== 0) && <>
                        {
                            responseJson && <>
                                {
                                    resultView == "default" ?
                                        <button
                                            type="button"
                                            className="btn btn-primary me-3 my-3 w-180px"
                                            onClick={() => setResultView("json")}
                                        >
                                            <KTIcon iconName="data" className="fs-1" />
                                            JSON Result
                                        </button> :
                                        <button
                                            type="button"
                                            className="btn btn-primary me-3 my-3 w-180px"
                                            onClick={() => setResultView("default")}
                                        >
                                            <KTIcon iconName="menu" className="fs-1" />
                                            Table Result
                                        </button>
                                }
                            </>
                        }
                    </>}
                    {/* <div
                        className="menu menu-sub menu-sub-dropdown w-200px w-md-180px"
                        data-kt-menu="true"
                    >
                        <div className="px-7 py-5" data-kt-user-table-filter="form">
                            <div className="mb-10">
                                <label className="form-label fs-6 fw-bold">Database:</label>
                                <select
                                    className="form-select form-select-solid fw-bolder"
                                    data-kt-select2="true"
                                    data-placeholder="Select option"
                                    data-allow-clear="true"
                                    data-kt-user-table-filter="role"
                                    data-hide-search="true"
                                    value={downloadFormat}
                                    onChange={(e) => {
                                        setExportFormat(e.target.value);
                                    }}
                                >
                                    <option value="mysql">MySql</option>
                                    <option value="erp">ERP</option>
                                    <option value="crm">CRM</option>
                                </select>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-success fw-bold px-6"
                                    data-kt-menu-dismiss="true"
                                    data-kt-user-table-filter="filter"
                                    onClick={handleExport}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* <button
                        type="button"
                        className='btn btn-primary me-3 my-3 w-200px'
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                    >

                        <KTIcon iconName='exit-up' className='fs-2' /> Export
                    </button> */}
                    {/* {Object.keys(result).length === 0 ? null : ( */}
                    {/* <button
                        className="btn btn-success me-3 my-3 w-150px"
                        id="kt_drawer_chat_toggle"
                    >
                        <KTIcon iconName="message-text-2" className="fs-1" />
                        Chat
                    </button> */}
                    {/* )} */}
                </div>
            </div>
        </div>
    );
};

