import React from 'react';
import { Blocks } from 'react-loader-spinner';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../../auth';
import { ProcessedDocumentResultProps } from '../DocumentProcessingModel';

// Define the type of respError
type RespError = boolean; // Adjust the type according to your use case

// Inside your component or function
let respError: RespError = false; // Initialize respError with a value
// ... (rest of your code)


const ProcessedDocumentResult: React.FC<ProcessedDocumentResultProps> = ({
  resultNew,
  resultView,
  tableHeader,
  responseJson,
  processAction,
  selectedFiles,
  processing,
  handleDocumentApproval,
}) => {
  const { currentUser } = useAuth();

  return (
    <div className={`card card-xl-stretch mb-5 mb-xl-8`} style={{ height: "70vh", overflowY: "scroll" }}>
      <div className="card-header border-0 p-0 align-items-center">
        <h3 className="card-title align-items-center fw-bold p-2">
          <KTIcon
            iconName="screen"
            className="fs-2x text-primary"
          />
          &nbsp; Processed Documents
        </h3>
        <span>
          {
            <>
              <>
                <button
                  type="button"
                  className="btn btn-success me-3 w-120px fs-9 p-2"
                  onClick={(e) => { handleDocumentApproval("accepted") }}
                  disabled={selectedFiles.length == 0}
                >
                  {
                    processAction == "accepted" ?
                      <span className="indicator-progress" style={{ display: "block" }}>
                        Processing...{" "}
                        <span className="spinner-border align-middle fs-6"></span>
                      </span>
                      :
                      <span className='d-flex align-items-center justify-content-between'>
                        <img src={toAbsoluteUrl("/media/custom-icon/accept-file.png")} alt="Thirdeye data" height={15} /> &nbsp;
                        {/* <KTIcon iconName='filter-tick' className='fs-2' /> */}
                        Accept
                      </span>
                  }
                </button>
                <button
                  type="button"
                  className="btn btn-danger me-3 w-120px fs-9 p-2"
                  disabled={selectedFiles.length == 0}
                  onClick={(e) => { handleDocumentApproval("rejected") }}
                >
                  {
                    processAction == "rejected" ?
                      <span className="indicator-progress" style={{ display: "block" }}>
                        Processing...{" "}
                        <span className="spinner-border align-middle fs-6"></span>
                      </span>
                      :
                      <span className='d-flex align-items-center justify-content-between'>
                        <img src={toAbsoluteUrl("/media/custom-icon/reject-file.png")} alt="Thirdeye data" height={15} /> &nbsp;
                        {/* <KTIcon iconName='cross-square' className='fs-2' /> */}
                        Reject
                      </span>
                  }
                </button>
                <button
                  type="button"
                  className="btn btn-warning me-3 w-180px fs-9 p-2"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_feedback"
                  disabled={selectedFiles.length == 0}
                >
                  {
                    processAction == "feedback" ?
                      <span className="indicator-progress" style={{ display: "block" }}>
                        Processing...{" "}
                        <span className="spinner-border align-middle fs-6"></span>
                      </span>
                      : <span className='d-flex align-items-center justify-content-between'>
                        <img src={toAbsoluteUrl("/media/custom-icon/feedback-file.png")} alt="Thirdeye data" height={15} /> &nbsp;
                        {/* <KTIcon iconName='filter-tick' className='fs-2' /> */}
                        Feedback
                      </span>
                  }
                </button>
              </>
              {
                currentUser?.usertype == 'superadmin' &&
                <button
                  type="button"
                  disabled={selectedFiles.length == 0}
                  className="btn btn-primary me-3 w-180px fs-9 p-2"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_assign"
                >
                  {
                    processAction == "assinging" ?
                      <span className="indicator-progress" style={{ display: "block" }}>
                        Processing...{" "}
                        <span className="spinner-border align-middle fs-6"></span>
                      </span>
                      : <span className='d-flex align-items-center justify-content-between'>
                        <img src={toAbsoluteUrl("/media/custom-icon/assign-file.png")} alt="Thirdeye data" height={15} /> &nbsp;
                        {/* <KTIcon iconName='filter-tick' className='fs-2' /> */}
                        Assign
                      </span>
                  }
                </button>
              }
            </>
          }
        </span>
      </div>
      {Object.keys(resultNew).length === 0 ? (
        <div className="row align-items-center">
          {processing ? (
            <Blocks
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
            />
          ) :
            <div className="card-body">
              {
                respError ?
                  <h4 className="text-center" style={{ color: "red" }}>Facing Technical Error Please Try Again</h4> :
                  <h4 className="text-center">The processed document will be display here</h4>
              }
            </div>
          }
        </div>
      ) : (
        <div className="my-1">
          {
            resultView == "json" && <div className="rounded mt-5 p-3 bg-dark text-light"
              style={{ maxHeight: "70vh", overflowY: "scroll" }}
            >
              {Object.entries(resultNew).map(([key, value]) => (
                <div key={key}>
                  {typeof value != "object" && (
                    <h6 className="text-light">
                      <span
                        style={{ fontWeight: "bold", color: "red" }}
                      >
                        {key}
                      </span>{" "}
                      : &nbsp;
                      <span>{JSON.stringify(value)}</span>
                    </h6>
                  )}
                </div>
              ))}
              <h6 className="text-light">
                <span
                  style={{ fontWeight: "bold", color: "red" }}
                >
                  TABLE DATA :
                </span>
              </h6>
              <pre>{JSON.stringify(responseJson, null, 2)}</pre>
            </div>
          }
          {
            resultView == "default" &&
            <div className="rounded p-3 bg-dark text-light overflow-auto" style={{ maxHeight: "62vh", overflowY: "scroll", width: '100%' }}>
              {/* Result Normal Ky value */}
              {Object.entries(resultNew).map(([key, value]) => (

                <div key={key}>
                  {(typeof value == "string" || typeof value == "number") && (
                    <h6 className="text-light">
                      <span
                        style={{ fontWeight: "bold", color: "red" }}
                      >
                        {key}
                      </span>{" "}
                      : &nbsp;
                      <span>{JSON.stringify(value)}</span>
                    </h6>)
                  }
                </div>
              ))}

              {/* Result Tables */}
              {Object.entries(resultNew).map(([key, value]) => (
                <div key={key}>
                  {!(typeof value == "string" || typeof value == "number") && Array.isArray(value) && ( // Add Array.isArray check
                    <>
                      {value.length > 0 && tableHeader.map((headers) => (
                        <table className="my-2" key={key}>
                          <tr>
                            {
                              headers.map((header) => (
                                <th style={{ border: "1px solid" }} key={header}>{header}</th>
                              ))
                            }
                          </tr>
                          {value.map((items, index) => (
                            <tr key={index}>
                              {Object.entries(items).map(([key, value], valueIndex) => (
                                <td style={{ border: "1px solid" }} key={valueIndex}>{String(value)}</td>
                              ))}
                            </tr>
                          ))}
                        </table>
                      ))}
                    </>
                  )}
                </div>
              ))}

            </div>
          }
        </div>
      )}
    </div>
  );
};

export default ProcessedDocumentResult;
