/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { HTMLAttributes } from 'react';
import { Link } from "react-router-dom";
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
type Props = {
  className: string;
  color: string;
  svgIcon?: string;
  iconColor: string;
  title: string;
  titleColor?: string;
  description: string;
  descriptionColor?: string;
  image?: string;
  tag?: string;
} & HTMLAttributes<HTMLDivElement>;

const FeatureCard: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  image,
  tag,
  ...divProps
}) => {

  return (
    <Link to={tag ? tag : ""} onClick={(e) => localStorage.setItem('tag', tag ? tag : "")} >
      <div className={`card bg-${color} hoverable ${className}`} style={{ boxShadow: '6px 6px 19px #0c101b, -6px -6px 19px #1c263f' }}>
        <div className='card-header align-items-center' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: '90px' }}>
          <div className={`text-${titleColor} fw-bold fs-2 mb-4 mt-5`} style={{ marginRight: '10px', flex: '4' }}>{title}</div>
          {svgIcon && <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x`} />}
          {image && (
            <img
              src={toAbsoluteUrl('/media/dsicon/' + image + '.png')}
              className={`text-${iconColor} fs-3x ms-n1`}
              alt={title}
              height={50}
            />
          )}
        </div>
        <hr className="mb-0" />
        <div className='card-body'>
          <div className={`fs-5 fw-semibold text-${descriptionColor}`}>{description}</div>
        </div>
        <div className='card-footer'>
          <button className='btn btn-outline btn-outline-primary w-100'>Explore The Functionality</button>
        </div>
      </div>
    </Link>
  );
};

export { FeatureCard };



