import React, { useEffect, useState } from 'react';
import { ResumeTable } from './ResumeTable';
import clsx from "clsx";
import { useAuth } from '../auth';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { ResumeLibraryProps } from './ResumeFormattingModel';

const ResumeLibrary: React.FC<ResumeLibraryProps> = ({
    tabProp,
    resumeData,
    unResumeData,
    handleSetLink,
    fetching,
    formatting,
    processAction,
    targetRef,
    toPDF,
    handleResumeProcess,
    compare,
    download,
    setRefresh,
    handleDocumentApproval,
    setSelectedFiles
}) => {
    const { currentUser } = useAuth();
    const [tab, setTab] = useState("processed");
    const [selectedFiles, setLocalSelectedFiles] = useState<string[]>([]);

    // Function to update selected files
    const updateSelectedFiles = (files: string[]) => {
        setLocalSelectedFiles(files);
        setSelectedFiles(files); // Update the selected files in the parent component
    };

    useEffect(() => {
        setTab(tabProp)
    }, [tabProp])

    useEffect(() => {
        setSelectedFiles(selectedFiles);
    }, [selectedFiles])

    return (
        <div className="card mb-5 mb-xl-8 mx-5">
            <div className="card-header card-header-stretch">
                <ul
                    className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap"
                    role="tablist"
                >
                    <li className="nav-item">
                        <a
                            className={clsx(`nav-link cursor-pointer`, {
                                active: tab === "processed"
                            })}
                            onClick={() => setTab("processed")}
                            role="tab"
                            style={
                                tab == "processed"
                                    ? { borderBottom: "4px solid #fff" }
                                    : { borderBottom: "" }
                            }
                        >
                            Processed Resume
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={clsx(`nav-link cursor-pointer`, {
                                active: tab === "unassosiated"
                            })}
                            onClick={() => setTab("unprocessed")}
                            role="tab"
                            style={
                                tab == "unprocessed"
                                    ? { borderBottom: "4px solid #fff" }
                                    : { borderBottom: "" }
                            }
                        >
                            Unprocessed Resumes
                        </a>
                    </li>
                </ul>
                {/* <div>
                    {
                        tab == "processed" &&
                        <>
                            <button
                                type="button"
                                className="btn btn-success me-3 my-3 w-120px fs-4"
                                onClick={(e) => { handleDocumentApproval("accepted") }}
                                disabled={selectedFiles.length == 0}
                            >
                                {
                                    processAction == "accepted" ?
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                            Processing...{" "}
                                            <span className="spinner-border align-middle fs-6"></span>
                                        </span>
                                        :
                                        <span className='d-flex align-items-center justify-content-between'>
                                            <img src={toAbsoluteUrl("/media/custom-icon/accept-file.png")} alt="Thirdeye data" height={22} /> &nbsp;
                                            Accept
                                        </span>
                                }
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger me-3 my-3 w-120px fs-4"
                                disabled={selectedFiles.length == 0}
                                onClick={(e) => { handleDocumentApproval("rejected") }}
                            >
                                {
                                    processAction == "rejected" ?
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                            Processing...{" "}
                                            <span className="spinner-border align-middle fs-6"></span>
                                        </span>
                                        :
                                        <span className='d-flex align-items-center justify-content-between'>
                                            <img src={toAbsoluteUrl("/media/custom-icon/reject-file.png")} alt="Thirdeye data" height={22} /> &nbsp;
                                            Reject
                                        </span>
                                }
                            </button>
                            <button
                                type="button"
                                className="btn btn-warning me-3 w-180px fs-4"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_feedback"
                                disabled={selectedFiles.length == 0}
                            >
                                {
                                    processAction == "feedback" ?
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                            Processing...{" "}
                                            <span className="spinner-border align-middle fs-6"></span>
                                        </span>
                                        : <span className='d-flex align-items-center justify-content-between'>
                                            <img src={toAbsoluteUrl("/media/custom-icon/feedback-file.png")} alt="Thirdeye data" height={22} /> &nbsp;
                                            Feedback
                                        </span>
                                }
                            </button>
                        </>
                    }
                    {currentUser?.usertype == 'superadmin' && (
                        <button
                            type="button"
                            disabled={selectedFiles.length == 0}
                            className="btn btn-primary me-3 my-3 w-180px fs-4"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_assign"
                        >
                            Assign
                        </button>
                    )}
                </div> */}
            </div>
            <form className="form">
                <div className="card-body">
                    <div className="tab-content pt-3">
                        <div className={clsx("tab-pane", { active: tab === "processed" })}>
                            <ResumeTable
                                targetRef={targetRef}
                                toPDF={toPDF}
                                className='mb-5 mb-xl-8'
                                resumeData={resumeData}
                                setLink={handleSetLink}
                                type={"processed"}
                                fetching={fetching}
                                formatting={formatting}
                                handleResumeProcess={handleResumeProcess}
                                compare={compare}
                                download={download}
                                setRefresh={setRefresh}
                                setSelectedFiles={updateSelectedFiles}
                            />
                        </div>
                    </div>
                    <div className="tab-content pt-3">
                        <div className={clsx("tab-pane", { active: tab === "unprocessed" })}>
                            <ResumeTable
                                targetRef={targetRef}
                                toPDF={toPDF}
                                className='mb-5 mb-xl-8'
                                resumeData={unResumeData}
                                setLink={handleSetLink}
                                type={"unprocessed"}
                                fetching={fetching}
                                formatting={formatting}
                                handleResumeProcess={handleResumeProcess}
                                compare={compare}
                                download={download}
                                setRefresh={setRefresh}
                                setSelectedFiles={updateSelectedFiles}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ResumeLibrary;
