import { DocumentReviewViewProps } from '../DocumentProcessingModel'
import { DocumentPreview } from './DocumentPreview'
import ProcessedDocumentResult from './ProcessedDocumentResult'


export default function DocumentReviewView({

    fileName,
    doctype,
    resultNew,
    resultView,
    tableHeader,
    responseJson, // Adjust the type according to your response data structure
    processAction,
    selectedFiles,
    processing,
    handleDocumentApproval,

}: DocumentReviewViewProps) {
    return (
        <div className="row d-flex justify-content-between position-relative">
            {/* File Preview UI */}
            <DocumentPreview
                fileName={fileName}
                doctype={doctype ? doctype : ""}
            />

            {/* Result UI */}
            <div className={`col-sm-12 col-md-6`}>
                <ProcessedDocumentResult resultNew={resultNew} resultView={resultView} tableHeader={tableHeader} responseJson={responseJson} processAction={processAction} selectedFiles={selectedFiles} processing={processing} handleDocumentApproval={handleDocumentApproval} />
            </div>
        </div>
    )
}